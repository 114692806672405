import { graphql } from 'gatsby';
import React from 'react';
import CheckList from '../../components/check-list';
import ServiceLayout from '../../components/service-layout';
import { pageContext } from '../../contexts/page-context';

const bookkeepingItems = [
  {
    h: 'จัดทำสมุดบัญชีต่าง ๆ ให้ครบถ้วนตามที่กฎหมายกำหนดให้จัดทำ',
    t: 'ได้แก่ สมุดเงินสด สมุดเงินฝากธนาคาร สมุดรายวันซื้อ สมุดรายวันขาย สมุดรายวันรับ-จ่าย สมุดรายวันทั่วไป สมุดบัญชีแยกประเภท',
  },
  { h: 'จัดทำบัญชีให้ถูกต้องตามมาตรฐานการบัญชีและพรบ.การบัญชีที่ประมวลรัษฏากรกำหนด' },
  { h: 'บันทึกรายรับ รายจ่าย และรายการค้าต่าง ๆ' },
  {
    h: 'บันทึกเงินเดือน ค่าแรง ค่าจ้างทำงาน',
  },
  {
    h: 'จัดทำค่าเสื่อมราคาและทุนตัดจำหน่ายของสินทรัพย์ต่าง ๆ',
  },
  { h: 'บันทึกบัญชีด้วยโปรแกรมคอมพิวเตอร์ทางบัญชีที่ทันสมัยและกรมสรรพากรรับรอง' },
  {
    t: 'กระทบยอดบัญชีฝั่งสินทรัพย์และฝั่งหนี้สิน',
  },
  {
    t: 'กระทบยอดรายการเดินบัญชีของธนาคาร',
  },
];

const financialItems = [
  { h: 'รวบรวมและสอบทานเอกสารทางบัญชีให้มีความสอดคล้องกับสถานะทางการเงินของธุรกิจ' },

  {
    h: 'วิเคราะห์รายการเอกสารเพื่อประกอบการบันทึกบัญชีรายได้ค่าใช้จ่ายและการปรับปรุงบัญชี',
    t: 'เช่น ใบส่งของ ใบแจ้งหนี้ใบเสร็จรับเงินเอกสารชำระหนี้ เอกสารนำฝากธนาคาร การบันทึกค่าเสื่อมราคา ฯลฯ',
  },

  { h: 'จัดทำงบการเงิน รายเดือน รายไตรมาศหรือรายครึ่งปีเพื่อนำเสนอต่อที่ประชุมและกระทรวงพาณิชย์' },

  {
    h: 'จัดทำการปิดงบการเงินให้ทุกเดือน',
    t: 'เพื่อให้ท่านได้ทราบผลการดำเนินงานและวางแผนได้การลงทุนหรือภาษีได้อย่างมีประสิทธิภาพ',
  },
  {
    h: 'ออกงบการเงินประจำปีพร้อมรายละเอียดประกอบงบการเงิน',
    t: 'ได้แก่ งบกระทบยอดเงินฝากธนาคารทุกบัญชี ทะเบียนทรัพย์สินและค่าเสื่อมราคา รายละเอียดลูกหนี้ เจ้าหนี้รายตัวคงเหลือ ณ วันสิ้นปี',
  },
];

const taxItems = [
  { h: 'จัดทำและยื่นแบบภาษีเงินได้้หัก ณ ที่จ่าย (ภ.ง.ด.1, ภ.ง.ด.2, ภ.ง.ด.3, ภ.ง.ด.53, ภ.ง.ด.54)' },
  { h: 'จัดทำและยื่นแบบภาษีเงินได้นิติบุคคลครึ่งรอบระยะเวลาบัญชี (ภ.ง.ด.51)' },
  { h: 'จัดทำและยื่นแบบภาษีเงินได้นิติบุคคลประจำปี (ภ.ง.ด.50)' },
  { h: 'จัดทำรายงานภาษีซื้อ/ภาษีขายและยื่นแบบภาษีมูลค่าเพิ่ม (ภ.พ.30, ภ.พ.36)' },
  { h: 'จัดทำและยื่นแบบภาษีธุจกิจเฉพาะ (ภ.ธ.40)' },
];

export default function AccountingServicePage({ data }) {
  return (
    <pageContext.Provider value={{ lang: 'th' }}>
      <ServiceLayout
        title={data.servicesThJson.name}
        subtitle={data.servicesThJson.description}
        url={data.servicesThJson.link}>
        <div className='flex flex-col'>
          <div className='text-black text-opacity-70'>
            เอเอฟ การบัญชี มีบริการที่หลากหลายเพื่อที่จะทำให้ท่านมีเวลาในการขับเคลื่อนธุรกิจของท่านได้อย่างเต็มที่
            เรามีทีมงานมืออาชีพที่จะคอยช่วยท่านในการจัดการปัญหาทางบัญชีต่าง ๆ
            ตั้งแต่การจัดเก็บสมุดบัญชีที่สำนักงานของท่านจนถึงการลงข้อมูลบัญชีในระบบซอฟต์แวร์บัญชี
            ซึ่งจะช่วยให้ท่านสามารถดำเนินธุรกิจได้อย่างราบรื่นและรวดเร็ว
            สามารถเข้าใจและสามารถจัดการงบการเงินของท่านได้อย่างมีประสิทธิภาพ
          </div>
          <CheckList header='การจัดทำสมุดบัญชี' list={bookkeepingItems} />
          <CheckList header='การจัดเตรียมงบการเงิน' list={financialItems} />
          <CheckList header='ภาษี' list={taxItems} />
        </div>
      </ServiceLayout>
    </pageContext.Provider>
  );
}

export const query = graphql`
  query AccountingServiceThQuery {
    servicesThJson(link: { eq: "/services/accounting" }) {
      name
      description
      link
    }
  }
`;
